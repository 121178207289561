/* eslint-disable consistent-return */
/* eslint-disable no-empty */
import DarkModeContext from "components/Contexts/DarkModeContext";
import Button from "components/Helpers/Button";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import InvestService from "services/invest";
import { formatNumber } from "utils/common";
import { INVEST_PACKAGE, INVEST_PERIOD } from "utils/constant";
import DataIteration from "../Helpers/DataIteration";
import SectionTitle from "../Helpers/SectionTitle";
import InvestItem from "./InvestItem";
import InvestProfit from "./InvestProfit";
import ModalConfirmGetOldBalance from "./ModalInvest/ModalConfirmGetOldBalance";

export default function Invests({ periods, getInvestHistories }) {
  const { system, balance: balanceCtx, depositBalance: depositBalanceCtx, getUserAuth, levelInvest, parameter } =
    useContext(GlobalContext);
  const { colors } = useContext(DarkModeContext);

  const [selectedInvestItem, setSelectedInvestItem] = useState(null);
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState({
    daily: 0,
    total: 0,
  });

  const [confirmModal, setConfirmModal] = useState(false);

  const profits = [
    {
      id: "1",
      name: "Daily profit",
      value: profit.daily,
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "2",
      name: "Total profit",
      value: profit.total,
      icon: "assets/images/total_payback.svg",
    },
  ];

  const oldBalance = useMemo(() => {
    if (balanceCtx) {
      return balanceCtx.tsib || 0;
    }
    return 0;
  }, [balanceCtx]);

  const depositBalance = useMemo(() => {
    if (depositBalanceCtx) {
      return depositBalanceCtx.tsib || 0;
    }
    return 0;
  }, [depositBalanceCtx]);

  useEffect(() => {
    if (amount > 0 && selectedInvestItem) {
      const period = INVEST_PERIOD.find((e) => e.id === selectedInvestItem);
      setProfit((p) => ({
        daily: formatNumber(period.profit_daily * +amount, 2),
        total: formatNumber(
          period.profit_daily * +amount * period.time * 30,
          2
        ),
      }));
    } else {
      setProfit({
        daily: 0,
        total: 0,
      });
    }
  }, [amount, selectedInvestItem]);

  const selectInvestItemHandler = (id) => {
    setSelectedInvestItem(id);
  };

  const handleSubmit = async () => {
    try {
      setConfirmModal(false);
      setLoading(true);
      const period = INVEST_PERIOD.find((e) => e.id === selectedInvestItem);
      await InvestService.invest({
        body: {
          amount,
          time: period.time,
        },
      });
      setLoading(false);
      setAmount(0);
      setSelectedInvestItem(null);
      getUserAuth();
      getInvestHistories();
      toast.success("Invest success!");
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  const handleSubmitFormInvest = async () => {
    if (!selectedInvestItem) {
      return toast.error("Please choose period");
    }
    if (amount < 500) {
      return toast.error("Amount incorrect");
    }

    if (amount > oldBalance + depositBalance) {
      return toast.error("Not enough balance");
    }

    if (amount > depositBalance){
      setConfirmModal(true);
    }
    else if (amount <= depositBalance){
      handleSubmit();
    }
  }

  const handleChangeAmount = (value) => {
    if (+value <= (oldBalance + depositBalance)) setAmount(value);
    else setAmount(oldBalance + depositBalance);
  };

  const levelNext = useMemo(() => {
    if (levelInvest) {
      const _index = INVEST_PACKAGE.findIndex((i) => i.id === levelInvest.id);
      const _level = INVEST_PACKAGE[_index + 1];
      if (_level) {
        return {
          ..._level,
          spend: _level.value - parameter.count.invest,
        };
      }
      return null;
    }
    return {
      ...INVEST_PACKAGE[0],
      spend: INVEST_PACKAGE[0].value,
    };
  }, [levelInvest, parameter]);

  return (
    <div className="col-span-7 md:col-span-3">
      <SectionTitle name="Invest" className="mb-4" />
      <div className="flex items-center justify-center">
        <h3 className="dark:text-white">
          Available deposit balance{" "}
          <span className="font-bold text-[#ff9800]">
            {formatNumber(depositBalance || 0)} {system.token}
          </span>
        </h3>
      </div>
      <div className="flex items-center justify-center">
        <h3 className="dark:text-white">
          Available balance{" "}
          <span className="font-bold text-[#ff9800]">
            {formatNumber(oldBalance || 0)} {system.token}
          </span>
        </h3>
      </div>
      <div>
        <span className="dark:text-white">Choose period</span>
        <div className="grid grid-cols-3 gap-5 mb-5">
          <DataIteration
            datas={periods}
            startLength={0}
            endLength={periods.length}
          >
            {({ datas }) => (
              <InvestItem
                checked={selectedInvestItem === datas.id}
                id={datas.id}
                name={datas.name}
                percent={datas.percent}
                thumbnail={datas.thumbnail}
                onSelect={selectInvestItemHandler}
              />
            )}
          </DataIteration>
        </div>
        <span className="text-sm font-bold dark:text-white leading-6">
          Minimum investment{" "}
          <span className="text-[#f44336]">
            500 <span className="text-[#ff9800]">{system.token}</span>
          </span>
        </span>
        {levelNext && (
          <div
            className="font-bold dark:text-white text-center p-4 border border-[#046ce6] rounded-lg mt-2"
            style={{
              background:
                "linear-gradient(to right, rgba(59,82,255,.3) 0%,rgba(207,5,213,.3) 99%)",
            }}
          >
            <p>
              You need to invest more{" "}
              <span className="text-[#ff9800]">
                {levelNext.spend} {system.token}
              </span>
            </p>
            <p>
              To receive direct commission{" "}
              <span className="text-[#4caf50]">{levelNext.percent}%</span>
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-3">
          {profits.map((datas) => (
            <InvestProfit
              key={datas.id}
              id={datas.id}
              name={datas.name}
              icon={datas.icon}
              value={datas.value}
            />
          ))}
        </div>

        <input
          value={amount}
          className={`w-full p-3 mt-3 outline-none border border-[${colors.blue_300}] rounded-lg dark:bg-transparent dark:text-white`}
          placeholder="Amount to invest"
          type="number"
          min={0}
          onChange={(e) => handleChangeAmount(e.target.value)}
        />
        <Button
          loading={loading}
          disabled={amount < 500 || !selectedInvestItem || loading}
          className={`w-full rounded-lg mt-3 p-2  text-white text-center flex justify-center items-center  ${
            amount >= 500 && selectedInvestItem ? "bg-pink" : "bg-gray-400"
          } `}
          onClick={() => handleSubmitFormInvest()}
        >
          {oldBalance >= 500 ? "INVEST" : "INSUFFICIENT BALANCE"}
        </Button>
      </div>
      <ModalConfirmGetOldBalance
        showModal={confirmModal}
        setShowModal={setConfirmModal}
        handleConfirm={handleSubmit}
        text={`Your deposit balance not enough to invest, do you want to get ${amount - depositBalance}$ from old balance?`}
      />
    </div>
  );
}
