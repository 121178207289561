
export default function TermAndCondition() {
  return (
    <div className="px-5 py-5 rounded-xl">
      <div className="text-white">
        <h1 className="mb-3 text-2xl font-bold">LEGAL DISCLAIMER</h1>
        <p className="mb-3">
          PLEASE READ THE ENTIRETY OF THIS SECTION CAREFULLY. NOTHING IN THIS
          WHITEPAPER CONSTITUTES INVESTMENT, LEGAL OR BUSINESS ADVICE AND YOU
          SHOULD CONSULT YOUR OWN ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY
          IN CONNECTION WITH THE TOSI (TSI) THE COMPANY, ANY OF THE MEMBERS
          NOR ANY SERVICE PROVIDERS WHO HAVE WORKED ON THE TOSI PROJECT IN ANY
          WAY WHATSOEVER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT
          DAMAGES OR LOSSES WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH
          ACCESSING THIS WHITEPAPER, THE WEBSITE AT HTTPS://TSIJP.JP (THE
          “WEBSITE”) OR ANY OTHER WEBSITES OR MATERIALS PUBLISHED BY THE
          COMPANY
        </p>
        <p className="mb-3">
          This Whitepaper is intended for general informational purposes only
          and does not constitute a prospectus, an o¬er of TOSI or securities,
          a solicitation for investment, nor an initiation for an o¬er to
          exchange any amount of cryptocurrency for TOSI. This Whitepaper
          audits and analyzes certain aspects of TOSI and the information
          stated herein is not legally binding and the study focuses
          exclusively on the activities and assets of TOSI. For a description
          of the risks associated with the TOSI enterprise, please see the
          Whitepaper section entitled “Risk Factors”. The information herein
          below may not be exhaustive and does not imply any elements of a
          contractual relationship. The Company does not and does not purport
          to make, and hereby expressly disclaims, all representations,
          warranties or undertaking (including without limitation warranties
          as to the accuracy, completeness, timeliness or reliability of the
          contents of this Whitepaper or any other materials published by the
          Company) to any person or entity. To the maximum extent permitted by
          law, the Company shall not be liable for any indirect, special,
          incidental, consequential or other losses of any kind, in tort,
          contract or otherwise (including, without limitation, any liability
          arising from default or negligence on the part of any of them, or
          any loss of revenue, income or profits, and loss of use or data)
          arising from the use of this Whitepaper or any other materials
          published, or its contents (including without limitation any errors
          or omissions) or otherwise arising in connection with the same.
          Nothing contained in this Whitepaper is or may be relied upon as a
          promise, representation or undertaking as to the future performance
          of TOSI. Where this Whitepaper includes information that has been
          obtained from third party sources, the Company has not independently
          verified the accuracy or completion of such information. Further,
          due to a change of circumstances this Whitepaper may become amended
          or replaced from time to time and in that event there are no
          obligations to update this Whitepaper or to provide recipients with
          access to any information beyond what is provided in this
          Whitepaper. Readers should monitor our Website closely for any
          updates
        </p>
        <p>
          1/ Any person’s purchase of the TOSI in violation of any anti-money
          laundering, counter-terrorism financing or other regulatory
          requirements that are imposed in any jurisdiction;{" "}
        </p>
        <p>
          2/ any person’s purchase of the TOSI in violation of any
          representation, warranty, obligation, covenant or other provision
          under this White Paper, which results in the failure of paying and
          withdrawing the TOSI;{" "}
        </p>
        <p>
          3/ failure or termination of the application development which
          results in the failure to deliver the TOSI;{" "}
        </p>
        <p>
          4/ delay or rescheduling of the application development and
          resulting failure to meet any published schedules;{" "}
        </p>
        <p>
          5/ any errors, flaws, defects or other issues in the source code of
          the TOSI;{" "}
        </p>
        <p>
          6/ any malfunction, breakdown, collapse, rollback or hard forking of
          the original public chain that the TOSI rely on;{" "}
        </p>
        <p>
          7/ failure of the TOSI to meet any specific purpose or its unfitness
          for any specific use;{" "}
        </p>
        <p>
          8/ failure to promptly and completely disclose any information
          relating to the development of TOSI;{" "}
        </p>
        <p>
          9/ any person’s divulgence, loss or destruction of the private key
          to his/her wallet for cryptocurrency or cryptographic (in particular
          the private key to the TOSI wallet);
        </p>
        <p>
          10/ any default, breach, infringement, breakdown, collapse, service
          suspension or interruption, fraud, mishandling, misconduct,
          malpractice, negligence, bankruptcy, insolvency, dissolution or
          winding-up of any third-party platform or exchange (if any) for
          TOSI;
        </p>
        <p>
          11/ any diference, conflict or contradiction between this White
          Paper and the agreement between any person and any third-party
          portal;
        </p>
        <p>12/ trading or speculation of the TOSI by any person; </p>
        <p>13/ listing or delisting of the TOSI on or from any exchange; </p>
        <p>
          14/ the TOSI being classified or treated by any government,
          quasi-government, authority or public body as a type of currency,
          securities, commercial paper, negotiable instrument, investment
          instrument or otherwise that results in it being banned, regulated
          or subject to certain legal restrictions;{" "}
        </p>
        <p>
          15/ any damage, loss, claim, liability, punishment, cost or other
          adverse impact that is caused by, associated with, in connection
          with, incidental to or relevant to the TOSI.
        </p>
        <p>
          This White Paper has not been approved by an authorised person. Any
          information to which this document relates is available only to a
          relevant person. This White Paper is only for relevant persons and
          non-relevant persons shall not take any action based on this
          document nor should he/she/they rely on it. It is a condition of you
          receiving and retaining this document that you warrant to the
          Company, its directors, and its o cers that you are a relevant
          person. For the avoidance of doubt, under no circumstances would a
          holder of TOSI be entitled to or receive (i) any form of interests
          whatsoever in whole or in part (including but not limited to equity
          interest, proprietary interest and ownership interest) or share in
          or claim against the Company and/or the cryptocurrency industrial
          mining operation, (ii) any voting rights at the board meetings or
          shareholders meetings of the Company and/or the cryptocurrency
          industrial mining operation, (iii) any management rights of the
          Company and/or the cryptocurrency industrial mining operation, (iv)
          any economic right (including any kind of right to payment,
          distribution, income, dividend, profit, or other return, or any sums
          to be paid, or likely to be paid, out of such return or profit) of
          or generated by the Company and/or the cryptocurrency industrial
          mining operation, and (v) any interest payments from the Company
          and/or the cryptocurrency industrial mining operation. No part of
          this Whitepaper is to be copied, reproduced, distributed or
          disseminated in any way without the prior written consent of the
          Company.
        </p>

        <h1 className="my-3 text-2xl">RISK FACTORS</h1>
        <p className="mb-3">
          There are a number of risks involved with the TOSI, as well as the
          future development, maintenance and running of TOSI. Some of these
          risks are beyond the control of the Company.
        </p>
        <p className="mb-3">
          Each reader should pay particular attention to the fact that,
          although the Company is established in Japan, TOSI does not have any
          specific physical presence. In addition, the legal and regulatory
          position of TOSI may vary depending on the facts and circumstances.
          They may therefore fall within the purview of one or more
          jurisdictions, at least to some extent, and the legal and regulatory
          implications may therefore be unexpected.
        </p>
        <p className="mb-3">
          Acquisition of the TSI shall be taken as an action after careful and
          prudent analysis and evaluation, and will be deemed as the relevant
          reader having been fully informed, advised and willing to bear all
          of the risks associated with it, as set out under this section. You
          agree that the Company shall not be liable for, amongst others, any
          loss in connection with any risk whether disclosed or not disclosed
          in White Paper. Any such loss will be at your sole and absolute
          risk. The relevant risks are as follows:
        </p>
        <p>1/ AMENDMENT OR TERMINATION OF THE CAMPAIGN</p>
        <p>2/ LIMITED AVAILABILITY OF SUFFICIENT INFORMATION</p>
        <p>3/ REGULATORY MEASURES</p>
        <p>4/ CRYPTOGRAPHIC PROTECTIONS</p>
        <p>5/ ABANDONMENT OR DEVELOPMENT FAILURE</p>
        <p>6/ COMPROMISED SECURITY</p>
        <p>7/ UNAUTHORIZED CLAIM OF TOSI</p>
        <p>8/ LOSS OF PRIVATE KEY</p>
        <p>9/ FORKING</p>
        <p>10/ POPULARITY</p>
        <p>11/ MARKET LIQUIDITY</p>
        <p>12/ PRICE VOLATILITY</p>
        <p>13/ THE COMPANY’S EXPOSURE TO CRYPTOGRAPHIC TOKENS</p>
        <p>14/ CONFLICT OF INTERESTS</p>
        <p>15/ POTENTIAL COMPETITORS</p>
        <p>16/ THIRD PARTY DEVELOPERS AND SUPPLIERS</p>
        <p>17/ POTENTIAL MISUSE OF THE TOSI’S TECHNOLOGIES AND BRAND</p>
        <p>18/ PRIVACY AND DATA RETENTION ISSUES</p>
        <p>
          19/ GENERAL RISKS RELATING TO THE USE OF THE INTERNET OR OTHER
          ELECTRONIC MEDIUM
        </p>
        <p>20/ TAX MATTERS RELATING TO THE COMPANY’S OPERATIONS</p>
        <p>21/ TAX MATTERS RELATING TO PARTICIPATION IN THE CAMPAIGN</p>
        <p>22/ PERSONAL CONNECTIONS WITH PARTICULAR JURISDICTIONS</p>
        <p>
          23/ FURTHER TOKEN SALES AND DEVELOPMENT AND SALE OF ADDITIONAL
          TOKENS
        </p>
        <p>24/ FAILURE TO OBTAIN LICENCES</p>
        <p>25/ DISCLAIMER</p>
        <p>
          Tosi global is a startup project on blockchain technology - The
          trend of the future. Participants accompanying tosi global read
          carefully and clearly understand the necessary information when
          participating.
        </p>
        <p>
          1, You agree to lend Tosi Global at the agreed interest rate (Tosi
          Global is the borrower and you are the lender)
        </p>
        <p>
          2, Investing and digital currencies can be risky for your assets,
          please read and understand the content with a spirit of voluntary
          cooperation and development for mutual benefit.
        </p>
        <p className="mt-3">
          The above is not an exhaustive list of the risks associated with
          TOSI and/or the cryptocurrence. Each reader shall familiarize itself
          with all associated risks prior to acquiring any TSI. Should any
          reader wish to understand more regarding the risks of TOSI and/or
          the cryptocurrency, you may wish to reach out a member of the team.
        </p>
      </div>
    </div>
  );
}
