/* eslint-disable prefer-const */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */

import { IconCommon } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { copyText, formatNumber } from "utils/common";
import { LEVEL_INT } from "utils/constant";
import Layout from "../Partials/Layout";
import Leveling from "./Leveling";
import MemberCategory from "./MemberCategory";
import TreeSystem from "./TreeSystem";

export default function Member() {
  const domain = window.location.origin;
  const { auth, parameter, system, usersGroup } = useContext(GlobalContext);
  const [members, setMembers] = useState(null);
  const [levelProgress, setLevelProgress] = useState(0);

  const getItems = (mapList, parentId, count_progress) => {
    const items = mapList.filter((e) => e.referred_by === parentId);
    items.forEach((el) => {
      console.log(el);
      if (LEVEL_INT[el.count.level] >= LEVEL_INT[parameter.level]){
        count_progress.count += 1 
      }
      const items = getItems(mapList, el.refer_code, count_progress);
      if (items && items != null && items.length > 0) {
        el.nodes = items;
      }
    });
    return items;
  };

  const getMembers = async () => {
    try {
      if (usersGroup) {
        const _members = usersGroup.map((m) => ({
          ...m,
          key: m.refer_code,
          label: (
            <span className="text-dark-gray dark:text-white ">
              {m.full_name} 🚀{m.count.level || "MEMBER"} 💸$
              {formatNumber(m.count.count.invest || 0)} 
              {/* 🫂$ {formatNumber(m.count.count.revenue_group || 0)} */}
            </span>
          ),
        }));

        const root = _members.find((u) => u.refer_code === auth.refer_code);

        const tree = _members.filter((u) => u.referred_by === auth.refer_code);

        let total_progress = 0;
        tree.forEach((el) => {
          let count_progress = {count: 0};
          const items = getItems(_members, el.refer_code, count_progress);
          if (items && items != null && items.length > 0) {
            el.nodes = items;
          }
          if (LEVEL_INT[el.count.level] >= LEVEL_INT[parameter.level]){
            count_progress.count += 1 
          }
          if(count_progress.count > 0) total_progress += 1;
        });
        setMembers(tree);
        setLevelProgress(total_progress)
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (usersGroup.length > 0) getMembers();
  }, [usersGroup]);

  const memberCategories = [
    {
      id: "1",
      name: "Your level",
      value: parameter.level || "MEMBER",
      icon: `assets/images/levels/${String(
        parameter.level || "member"
      ).toLowerCase()}.png`,
    },
    {
      id: "2",
      name: "Member",
      value: usersGroup?.length || 0,
      icon: "assets/images/member1.svg",
    },
    {
      id: "3",
      name: "Total sales",
      value: formatNumber(parameter?.count.revenue_group || 0),
      icon: "assets/images/total_sales.svg",
      unit: system.token,
    },
  ];

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  return (
    <>
      <Layout>
        <MemberCategory data={memberCategories} />
        <div className="mt-3">
          <div className="text-dark-gray dark:text-white font-bold">
            <p>Use the below link to invite your friends:</p>
            <div className="flex items-center">
              <p className="url-share mr-1">{`${domain}/signup?referred_by=${auth?.refer_code}`}</p>
              <IconCommon
                name="copy.svg"
                width={20}
                height={20}
                onClick={(_) =>
                  handleCopy(`${domain}/signup?referred_by=${auth?.refer_code}`)
                }
              />
            </div>
          </div>
        </div>

        <TreeSystem members={members} />
        <Leveling members={members} levelProgress={levelProgress}/>
      </Layout>
    </>
  );
}
